@import 'design/template/mixin_templates';

/*-----------------------------
    GENERAL
-------------------------------*/

html, body{
    outline:0;
    margin:0;
    padding:0;
}
body{
    font-family:arial,sans-serif;
    font-size: 12px;
    line-height: 1.2;
}
@include titreBaseSize();

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6{
    margin: 0;
    padding: 0;
}

input,select,textarea{
    font-family:arial,sans-serif;
    font-size:.85em;
    border-radius:5px;
    padding:5px;
}
img{
    border:none;
}
a img{
    border:none;
}
input[type=submit], #paiementchoix .button, .button{
    cursor:pointer;
    border:none;
    border-radius:5px;
    font-size:.85em;
    padding:6px 9px;
}
.bx-wrapper .bx-viewport{
    left:0;
}
iframe{
    max-width:100% !important;
}
.clearer{
    clear:both;
}
.center{
    text-align:center;
}
h2, .h2{
    margin-bottom:15px;
}

/*-----------------------------
    HEADER
-------------------------------*/

#header{
    height:260px;
    position:relative;
    overflow:hidden;
    margin-bottom:0;
}
#header_menu{
    width:240px;
    margin:15px 20px 20px;
    position:relative;
    z-index:2;
}
#header_menu a{
    text-decoration:none;
    display:block;
    float:left;
    margin-right:15px;
    text-align:center;
}
#header_mask{
    position:absolute;
    left:290px;
    top:0;
    height:260px;
    width:200px;
    z-index:996;
}
#header_mask2{
    position:absolute;
    right:10px;
    top:204px;
    height:57px;
    width:480px;
    z-index:996;
}
#logo{
    z-index:997 !important;
    display: inline-block;
    img{
        z-index:999 !important;
        max-width: 100%;
        max-height:260px;
    }
}

.slogan{
    width:217px;
    position:absolute;
    left:20px;
    font-size:12px;
    top:185px;
    font-style:italic;
    text-align:center;
    min-height:20px;
}
#header #contact{
    position:absolute;
    right:20px;
    top:20px;
    width:145px;
    height:60px;
    border-radius:5px;
}
#header #contact a{
    display:block;
    text-align:right;
    padding-right:60px;
    text-transform:uppercase;
    font-weight:700;
    font-size:11px;
    text-decoration:none;
    margin:15px 5px 5px;
}

/*-----------------------------
    CONTENT
-------------------------------*/

#wrapper{
    width:980px;
    position:relative;
    margin:0 auto;
}
#wrapper_inner{
    width:100%;
    padding:0 10px;
    box-sizing:border-box;
    margin:0 auto;
}
#content{
    position:relative;
}
#content a:hover{
    text-decoration:none;
}
#content_inner{
    margin:0 20px;
}
#content_right{
    float:right;
    width:68.75%;
    margin-left:20px;
    margin-right:10px;
    min-height:500px;
}

/*-----------------------------
    MENU
-------------------------------*/

nav#nav-principal{
    float:left;
    width:20%;
    border-radius:0 25px 25px 0;
    margin-left:-30px;
    border-left:0;
    position:absolute;
    padding-left:30px;

}
nav#nav-principal ul{
    list-style-type:none;

    padding:20px 0;
}
nav#nav-principal > ul li{
    position:relative;
    margin-bottom:10px;
}
nav#nav-principal > ul li a{
    text-decoration:none;
    text-transform:uppercase;
    font-weight:700;
    padding:1px;
}
nav#nav-principal > ul > li > a{
    display: inline-block;
    width: 100%;
    padding:5px 0;
}
nav#nav-principal > ul > li > ul{
    display:none;
    list-style-type:square;
    list-style-position:inside;
    position:absolute;
    top:100%;
    left:15px;
    z-index:999;
    padding:10px;
    &, ul{
        color:#777;
        background:#ddd;
        border-bottom:2px solid #666;
    }
    ul{
        list-style-type:square;
        list-style-position:inside;
        margin-left:15px;
    }
}
nav#nav-principal > ul li ul li:last-child{
    margin-bottom:0;
}
nav#nav-principal > ul li:hover ul{
    display:block;
}
#header_menu a{
    margin-bottom:10px;
}
nav#nav-principal > ul #facebook{
    margin-left:0;
    margin-top:20px;
    display:block;
}
#header_menu a span.sous_navigation_button_p, nav#nav-principal>ul li a span.sous_navigation_button_p{
    display:block;
    float:left;
    width:15px;
    height:15px;
    margin-right:5px;
    box-sizing:border-box;
}

/*-----------------------------
    BOUTIQUE
-------------------------------*/

.produits img{
    margin:0!important;
}
.produits select{
    width:100%;
}
#content .produits select {
    height: 27px;
}
.produits p{
    margin:5px 0!important;
}
.produits p.attr{
    height:auto;
}
.produits p.titreprod{
    font-size:.9em!important;
    line-height:1em!important;
}
.produits .productImageWrap{
    height: 120px;
}
.productImageWrap img{
    margin:0 !important;
    max-width:100% !important;
}
body #fiche-produit .wrap-images .grande-image {
    height: auto;
    > a{
        height: auto;
        &::after{
            content:'';
            display: block;
            clear: both;
        }
    }
}
.produits .container-etiquette {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
}
.produits .produit_etiquette {
    width: 100%;
}
#content .produit_etiquette2 {
    bottom: 0;
}
.produit_etiquette h3, .produit_etiquette2 h3, .produit_etiquette3 h3,
.produit_etiquette .h3, .produit_etiquette2 .h3, .produit_etiquette3 .h3 {
    font-size: 1em;
}
.prixprod{
    font-weight:700;
    font-size:1.1em!important;
}
.nomprod{
    font-weight:700;
    font-size:.9em!important;
    line-height:1em!important;
}
.nomprod a{
    text-decoration: none;
}

.block-btn-addbasket{
    height: 13px;
}

.addbasket, .hors-stock{
    display:block;
    border-radius:5px;
    font-size:.85em;
    text-align:center;
    text-decoration:none;
    cursor:pointer;
    padding:7px 10px;
    height: auto;
    box-sizing: border-box;
    float:none !important;
}
.hors-stock {
    margin-top: 0;
}

#content .produits{
    width:142px;
    float:left;
    border-radius:5px;
    margin-right:15px;
    margin-bottom:15px;
    position:relative;
    padding:10px;
}
#content .produits select{
    width:121px!important;
    margin-top:5px;
}
#content .produits p.nomprod{
    margin-top:7px;
    font-size:.95em;
    text-align:center;
}
#content .produits .prixprod{
    font-weight:700;
    margin-top:7px;
    font-size:1em;
    text-align:center;
}
#content .produits .oldprix{
    text-decoration:line-through;
    font-size:.9em;
    color: red;
}
#content .remise{
    position:absolute;
    top:8px;
    left:8px;
    font-weight:700;
    font-size:.85em;
    text-align:center;
    padding:5px;
    z-index: 2;
}
#content .is_etiquette .remise{
    top: 36%;
    right: 8px;
    left: auto;
}
#content .remise span{
    font-size:1.1em;
}
#panier{
    margin-bottom:-3px;
}



#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > .button {
        flex: 0 1 auto;
        margin: 15px 0;
        display: inline-block;
        .fa {
            margin-right: 10px;
        }
    }

    > .button + .button {
        margin-left: 15px;
    }

}

.modal {
    z-index: 2041;
}

.modal-backdrop {
    z-index: 2040;
}


/*-----------------------------
    GALERIE
-------------------------------*/

#slider{
    width:680px;
    position:absolute;
    right:0;
    top:0;
}
#slider_inner span{
    position:relative;
}
#slider_inner span img{
    position:relative;
    width:100%;
}
#slider_inner span span{
    position:absolute;
    z-index:9999;
    font-size:10px;
    top:10px;
    right:-35px;
    border-radius:25px;
    padding:45px 45px 15px 15px;
}
#slider .bx-prev{
    position:absolute;
    top:60px;
    left:0;
    text-decoration:none;
    padding:20px;
}
#slider .bx-next{
    position:absolute;
    top:60px;
    left:880px;
    text-decoration:none;
    padding:20px;
}
#slider .bx-wrapper,#slider .bx-window{
    height:100% !important;
    width:100% !important;
}

#content .galerie{
    float:left;
    border-radius:5px;
    text-align:center;
    padding:10px;
}
.galerie_size {
    width: 24%;
}
.galerie_gutter {
    width: 1%;
}
.galerie{
    box-sizing: border-box;
    width: 24%;
    margin: 5px .5%;
}
#content .galerie a.addcommentaires{
    margin-top:7px;
    display:block;
    font-size:.85em;
}
#slidedom img{
    max-width:100% !important;
}

/*-----------------------------
    CONTACT
-------------------------------*/

#contactleft{
    float:left;
}
#contactright{
    float:right;
}
.texthautcontact{
    margin-bottom:40px;
}
#form label{
    display:block;
    margin:15px 0 10px;
}
#formcol1{
    float:left;
    width:200px;
}
#formcol2{
    float:right;
    width:450px;
}
#formcol2 textarea{
    width:440px;
}

/*-----------------------------
    LIVRE D'OR
-------------------------------*/

.message_lo{
    border-radius:5px;
    position:relative;
    margin:20px 0;
    padding:20px;
    background-color: #fff;
    color: #222;
}
.message_lo h4,
.message_lo .h4{
    font-size:.9em;
}
.message_lo .note{
    position:absolute;
    right:15px;
    bottom:15px;
    border-radius:5px;
    font-weight:700;
    font-size:.9em;
    padding:5px;
}
.message_lo .msessage{
    margin-top:10px;
    font-style:italic;
    margin-bottom:30px;
}
.message_lo .web{
    margin-top:5px;
    font-weight:700;
}
#content .addmsglo{
    font-family:arial,sans-serif;
    cursor:pointer;
    border:none;
    border-radius:5px;
    font-size:.85em;
    text-decoration:none;
    width:180px;
    display:block;
    text-align:center;
    padding:6px 9px;
}

/*-----------------------------
    FOOTER
-------------------------------*/

#footer{
    text-align:center;
    margin-top:20px;
    font-size:10px;
}

#footer a{

}

#footer_inner{
    padding:20px;
}

/*-----------------------------
    AUTRE
-------------------------------*/

#resume table+div .col.s6:first-child label{
    width: 100%;
    margin-bottom: 7px;
}
.profile a.button, .profile button {
    font-size: 12px;
}

/********************* A GARDER POUR LES MODELS ********************/

/*Gerer les images dans les tableaux*/
.links_p{
    position:relative;
}
#content img{
    max-width:100% !important;
    height:auto !important;
    display:inline-block;
}
#content .zoomPupImgWrapper > img, #content .zoomWrapperImage > img{
    max-width:none !important;
}

table{
    position:relative;
    table-layout:fixed;
}
td{
    max-width:50%;
}
#content td img{
    display:table-cell;
    max-width:100% !important;
}
#footer.footer_p .links_p td img, #footer.footer_p .links_p img{
    width:auto !important;
    max-width:100% !important;
}
.links_p table{
    max-width:100% !important;
}
/*Titre des commentaires du livre d'or ne prend pas toute la largeur*/
.message_lo h4,
.message_lo .h4{
    width:80%;
}
.container-comment, .content-cmonsite:not(.form-search){
    overflow:hidden;
}
/*Slider*/
#sliderContent, .sliderImage, .sliderImage span, .sliderImage li{
  width:100% !important;
}
.sliderImage{
  text-align:center !important;
}
.sliderImage img{
  text-align:center;
  width:auto;
  max-width:100% !important;
}
.produits, .galerie{
    box-sizing:border-box;
}
.preview{
    max-width:95%;
}

#fiche-produit{
    .wrap-description{
        .h1, h1{
            font-size: 24px;
        }
    }
}

/* Commandes */

#order .commandes td{
    padding:10px 0;
    border-top:1px solid rgba(128,128,128,.1);
    .button{
        text-decoration: none;
    }
}
#order .commandes-details{
    > td > div{
        border-bottom:none;
    }
    .infosclient.s6{
        &, &:first-child{
            width: 100%;
            margin:10px 0 0;
        }
    }
}

/******************************************\
|************* MEDIA QUERIES **************|
\******************************************/

@media screen and (max-width: 1250px){
    .slidershadow{
        width:100%;
    }
    #footer img{
        max-width: 100%;
        height: auto;
    }
    .search-bar{
        width:95%;
    }
}
@media screen and (max-width: 990px){
    #wrapper{
        width:768px !important;
    }
    .contentleft, .contentright{
        width:100% !important;
    }
    #header_menu{
        width:24%;
    }

    /************* SLIDER **************/
    /*#slider{
        width:100% !important;
        height:450px !important;
    }*/
    #slider{
        height:235px;
        width:530px;
        overflow:hidden;
    }
    #sliderContent .sliderImage img{
        width:auto !important;
    }
    #header_mask{
        left:228px;
    }
    #header_mask2{
        width:330px;
        background-size:100% auto;
        top:209px;
    }

    /************ FIN SLIDER *************/
    .produits{
        margin:0 10px 20px !important;
    }
    #top-content{
        padding : 0 48px;
    }

    .coin-slider{
        margin-bottom:20px;
    }
    #contentpage_center{
        overflow:hidden;
    }
    /*Suppression des blocs en dehors du contenu*/
    #block-flottant-search, #minisite-sidebar, #left_bandeau{
        display: none;
    }
    .bx-wrapper{
      height:100% !important;
      width:100%;
        margin-bottom:0 !important;
    }
    .bx-wrapper a.one_image{
        height:150px;
    }

    #boutique_class .produits{
        width:177px;
        margin:0 5px 20px !important;
    }
    #boutique_class .produits img{
        width:144px;
    }
    #boutique_class .produits .produit_etiquette, #boutique_class .produits .produit_etiquette2{
        width:144px;
    }
    #boutique_class .produits h3,
    #boutique_class .produits .h3{
        font-size:1.3em;
    }

    /*Position des deux blocs centraux*/
    .categorie, .div-content{
        float:none;
        display:block;
        margin:3%;
        width:94%;
        box-sizing:border-box;
        padding:1%;
    }

    .galerie_size {
        width: 32%;
    }
    .galerie_gutter {
        width: 1%;
    }
    .galerie{
        box-sizing: border-box;
        width: 32%;
        margin: 5px .5%;
    }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
    .side-tabs.etapes,
    div#resume .block-recap-panier,
    div#resume div.next-step,
    div#information #connexion-inscription,
    div#information div.next-step,
    #infoscommande,
    div#finish .block-info-payment,
    div#finish div.next-step,
    div#resume h4,
    div#finish h4,
    .message-commande {
        width: 100%;
    }

    h4 {
        margin: 0;
    }

    #connexion-inscription > .col.s6 {
        padding: 0;
        width: 100%;
        margin: 15px 0;
    }

    div#resume .block-resume-price .col.s6,
    div#finish .block-resume-price .col.s6 {
        width: 100%;
    }

    #information #infoscommande .pull-right {
        /*Hack pour passer au dessus du important style all design, pour assurer un rendu propre*/
        float: none !important;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }
}

@media screen and (max-width: 768px){
    .slogan{
        left:0 !important;
        top:0 !important;
        right:auto !important;
        bottom:auto !important;
        text-align:center !important;
        font-size:18px !important;
        width:100% !important;
        position:relative !important;
        margin-bottom:15px !important;
        height: auto !important;
    }
    .logo{
        padding-bottom: 15px;
        position: static !important;
        display:block !important;
        margin:0 auto !important;
        padding-top:15px;
        height:auto !important;
        text-align:center;
        img{
            max-height: 70px !important;
            width: auto !important;
            max-width:100% !important;
            height:auto !important;
            position:static !important;

        }
    }

    #wrapper{
        width:360px !important;
    }
    #wrapper nav#nav-principal > ul li{
        height:auto;
    }
    #content_right{
        width:100%;
        margin:0;
    }
    .search-bar{
        width:90%;
    }
    #slider{
        height:150px !important;
    }
    .bx-viewport{
        top:0 !important;
    }
    #header_menu{
        width:98%;
        margin:10px 1%;
    }
    nav#nav-principal > ul{
        position:static !important;
        width:100% !important;
        border:0;
    }
    #content .produits{
        width:95%;
        float:none;
        margin:10px 2.5%;
    }
    #header_menu a{
        display:inline;
    }
    #header{
        margin:0 10px;
    }
    #top-content{
        padding: 0 10px;
    }
    #content{
        padding:1px 0 15px;
    }
    #header_mask, #header_mask2{
        display:none;
    }
    #slider{
        width:100%;
        position:static;
    }
    /*Fiche produit*/
    #fiche-produit #formulaire .form-group{
        float:none !important;
    }
    #fiche-produit .input-form-petit{
        width:auto !important;
    }
    #fiche-produit .input-form-moyen{
        width:100% !important;
    }
    #fiche-produit .wrap-images, #fiche-produit .wrap-description{
        width:100% !important;
        float:none !important;
    }
    #wrapper table{
        width:100% !important;
    }
    /*Desactivation du hover sur images produits*/
    .zoomWindow, .zoomPup{
        display:none !important;
    }
    .zoomPup, .zoomPad{
      cursor:initial !important;
    }
    .zoomPad{
        display:inline-block;
        width:80% !important;
        margin:0 10% !important;
        text-align:center;
    }
    .zoomPad img{
        width:100% !important;
        max-height:100% !important;
        opacity:1 !important;
    }
    /*Fin desactivation*/
    #boutique .produits-base {
        width: 48%;
    }

    #contentpage .produits, #boutique_class .produits{
        width:90%;
        margin:10px 5% !important;
        padding:10px;
        position:relative;
    }
    .productImageWrap{
        text-align:center;
    }
    .produits img{
        display:inline-block;
    }
    #contentpage .produits .produit_etiquette, .produits .produit_etiquette, .produits .produit_etiquette2{
        width:100% !important;
    }

    #content .galerie{
        width:100% !important;
        margin-left:0 !important;
        height:auto !important;
    }
    #content .galerie img{
        width:auto !important;
        height:auto !important;
        max-width:100% !important;
    }


    form .row .col.s3{
        width: 25%;
    }
    .form-group textarea{
        width: 100% !important;
    }
    .thumbnail{
        float:left;
        width:42%;
        margin:2.5%;
    }
    td{
      max-width:100%;
    }
    /*Ajuste tous les tableaux des templates*/
    .template table, .template  td, .template  tbody, .template  tr{
        width:100%;
        display: inline-block;
        margin:5px auto !important;
    }

    @import 'design/helper/responsive/mobile/mobile';


    .ui-datepicker{
        width: 98% !important;
        font-size: .75em;
        left: 0 !important;
    }

    .message_lo{
        margin: 15px 5px;
    }
    .search-bar{
        width:85%;
    }
    #panier {
        right: 0 !important;
        width: 100% !important;
        box-sizing: border-box;
    }
    .ui-datepicker{
        left:0 !important;
    }
    a.addmsglo{
        width:100%;
        box-sizing: border-box;
    }


    .produits-base, .galerie-base{
        width: 75% !important;
        margin: 0 auto 12px !important;
        padding: 15px;
        float: none;
        display:block;
    }
    #carousel .produits-base{
        width:150px !important;
    }
    .thumbnail{
        float:none;
        width:75%;
        margin: 0 auto 12px;
        padding: 15px;
    }
    .content-payment-global{
        font-size:15px !important;
    }
    a.addbasket.button{
        display:inline-block !important;
        padding-left:15px;
        width:auto !important;
    }
    #wrapper .in{
        padding: 15px 8px;
    }
    ul.sous_navigation{
        margin: 15px 0 15px 10px;
    }
    .message_lo{
        margin: 15px 0;
    }
    #content form{
        overflow: hidden;
    }
    form .row .col{
        padding: 0 2px;
    }
    form .row .col.s3{
        width: 50%;
    }

    .row .col.s6{
        width:100%;
    }

    .produits .productImageWrap{
        height:auto;
    }

    #slider_inner span span{
        padding:10px;
        right:0;
    }

    //Template Marque
    .template_marque_1{
        .marque-group{
            @include elemTailleCol(1, 0, true);
        }
    }
    .template_marque_2{
        .marque-el{
            @include elemTailleCol(1, 0, true);
        }
    }
}
@media screen and (max-width: 400px){
    #wrapper{
        width: 95% !important;
        box-sizing: border-box;
    }

}

@media screen and (min-width: 768px){
    nav#nav-principal > ul > ul li:hover > ul{
        display:block !important;
    }
    .menu_burger{
      display:none;
    }
}

@media screen and (max-width: 768px){
    .menu_burger{
        text-align:center;
        font-size:1.2em !important;
        border-radius:20px;
    }
    nav#nav-principal > ul > li > ul ul{
        margin-left:0 !important;
    }
    nav#nav-principal{
        width: 100%;
        border-radius:25px;
        margin-left: 0;
        border-left-width: 3px !important;
        border-left-style:solid !important;
        position: relative !important;
        top:0 !important;
        float:none;
        padding:0 !important;
    }
    nav#nav-principal > ul{
        position:relative;
    }
    /* Nav mobile */
    nav#nav-principal > ul{
        margin:0;
    }
    nav#nav-principal > ul > li{
        float:left;
        z-index:auto!important;
        position:relative;
        width:100% !important;
        padding:0;
        text-align:center;
    }
    #header{
        height:auto;
    }
    .bx-controls.bx-has-pager{
        display:none !important;
    }
    #slider{
        height:auto !important;
        background: transparent !important;
    }
    #slider .bx-wrapper, #slider .bx-window{
        height:auto !important;
    }
    nav#nav-principal > ul > li{
        border:none;
        width:100%;
        display:block;
        margin:0;
        position:relative;
        box-sizing:border-box;
    }
    .menu_burger{
        display:block;
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
        padding:10px 5px;
        width:100%;
        position:relative;
        z-index:9999;
        box-sizing:border-box;
    }
    .menu_burger::before{
        font-family: FontAwesome, sans-serif;
        content: "\f0c9";
        margin-right: 5px;
    }
    nav#nav-principal > ul.nav_fermee > li{
        display:none;
    }
    nav#nav-principal > ul.nav_ouverte > li{
        display: block;
        width: 100%;
        box-sizing:border-box;
        border:none;
        margin:0 !important;
        float:none;
    }
    nav#nav-principal > ul li{
        margin-right: 0;
        display: block;
        position: relative;
        overflow:visible;
        text-align:center;
        box-sizing:border-box;
    }
    nav#nav-principal > ul li a{
        height: auto;
        float: none !important;
        margin:0 auto !important;
        top:0 !important;
        width:auto !important;
        display:inline-block;
        text-align:center;
        box-sizing:border-box;
        padding:10px 0;
    }
    nav#nav-principal > ul li a span{
        font-size: 17px;
    }
    .hasDropdown ul + span::after{
        font-family: FontAwesome, sans-serif;
        content: "\f0d7";
    }
    .dropDown{
        position: absolute;
        right: 0;
        top: 0;
        font-weight: bold;
        height: 31px;
        width: 12%;
        cursor: pointer;
        text-align: center;
        font-size: 1.2rem;
        padding-top: 5px;
        background-color: transparent !important;
        display:block !important;
        opacity:1 !important;
    }
    nav#nav-principal > ul li ul{
        position:absolute;
        z-index:9999;
    }
    nav#nav-principal > ul li ul li a{
        padding: 15px 0;
        text-align:center;
    }
    nav#nav-principal > ul li ul li{
        text-align:center;
        border-bottom: none;
        margin:0;
    }
    nav#nav-principal > ul > li > a.current, nav#nav-principal > ul li a:hover{
      background:transparent;
    }
    .sousmenu_ouvert{
      z-index:99999 !important;
    }
    nav#nav-principal > ul li ul{
        position: absolute;
        z-index: 9999;
        left: 0;
        padding: 2px;
        box-sizing: border-box;
        top:100%;
        width: 100% !important;
    }
    nav#nav-principal > ul li.sousmenu_ouvert > ul{
        display: block;
    }
    nav#nav-principal > ul li.sousmenu_ouvert > ul li a{
        margin:5px;
    }
    nav#nav-principal > ul li.sousmenu_ouvert > ul li a:hover{
        text-decoration:none;
    }
    /* FIN Nav mobile */
}



#fiche-produit .wrap-description .ajouter-panier .quantite{
    margin-right: 0;
    border-radius: 0;
}
.wrap-description .bloc-quantite .bloc-bt-quantity .bt-quantity{
    height: auto;
}